<template>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<section v-if="isLogin" class="top top--small">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<small>
								<span class="mypage__my-type active">
									{{type}}
								</span> <!-- 재번역 체크 -->
								<!-- <span class="mypage__my-auth"  @click="handleOpenArmy">
									제휴회원 인증하기
								</span> -->
							</small>
							<span>
								<strong>{{userData.nick_nm}}</strong>
								<router-link to="/mypage/password">
									<i class="icon icon-write"></i>
								</router-link>
							</span>
						</div>
					</div>
					<div class="mypage__my-btn">
						<div class="mypage__my-grad">
							<!-- 일반 -->
							<img src="@/assets/images/grad_default.png" v-if="grade === '일반' " alt="일반">
							<!-- VIP -->
							<img src="@/assets/images/grad_vip.png" v-else-if="grade === 'VIP'" alt="VIP">
							<!-- VVIP -->
							<img src="@/assets/images/grad_default.png" v-else alt="VVIP">
							
						</div>
						<router-link to="/mypage/benefit">
							<button type="button" class="btn-modify">
								{{t('10859')}}<!-- 재번역 체크 -->
							</button>
						</router-link>
					</div>
				</div>
			</section>
			<section v-else class="top top--small">
				<router-link to="/layers/login">
					<h2 class="top__subtitle">
						{{t('10367')}}
						<i class="icon icon-right--white"></i>
					</h2>
				</router-link>
				<div class="top__notice">
					{{t('10368')}}
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<!-- 마이페이지 추천인 -->
						<div v-if="isLogin" class="mypage__recommend">
							<div class="mypage__recommend-list">
								<div @click="onClickRecommendCode">
									{{t('10369')}}
									<strong v-if="mmy0101.length > 0">
										{{mmy0101[0].recomm_cd}}
									</strong>
								</div>
								<span class="mypage__dot"></span>
								<router-link to="/mypage/recommend" custom v-slot="{ navigate }">
									<div v-if="mmy0101.length > 0" @click="navigate">
										총 초대회원수 {{mmy0101[0].recomm_cnt}}명 <!-- 재번역 체크 -->
									</div>
								</router-link>
							</div>
						</div>
						<div v-else class="mypage__recommend">
							{{t('10371')}}
						</div>
						<!-- 마이페이지 포인트 -->
						<div class="mypage__point">
							<router-link to="/mypage/point" custom v-slot="{ navigate }">
								<div class="mypage__point-item" @click="isLogin ? navigate() : handleOpenLogin()">
									<i class="icon icon-point2"></i>
									<span>{{t('10348')}}</span>
									<strong class="mypage__point-number">
										{{point}}
									</strong>
								</div>
							</router-link>
							<router-link to="/mypage/coupon" custom v-slot="{ navigate }">
								<div class="mypage__point-item" @click="isLogin ? navigate() : handleOpenLogin()">
									<i class="icon icon-ticket"></i>
									<span>{{t('10064')}}</span>
									<strong class="mypage__point-number">
										{{coupon}}
									</strong>
								</div>
							</router-link>
							<router-link to="/mypage/stamp" custom v-slot="{ navigate }">
								<div class="mypage__point-item" @click="isLogin ? navigate() : handleOpenLogin()">
									<i class="icon icon-stamp"></i>
									<span>{{t('10430')}}</span>
									<strong class="mypage__point-number">
										{{stamp}}
									</strong>
								</div>
							</router-link>
						</div>
						<!-- 마이페이지 메뉴 -->
						<ul class="mypage__menu">
							<li>
								<router-link :to="isLogin ? '/mypage/booking/list' : '/guest'" >
								{{t('10351')}}
									<!-- 재번역 체크 -->
									<div class="mypage__menu-badge" v-if="mmy0101.length > 0">
										{{mmy0101[0].stay_rsv_cnt > 99 ? '99+' : mmy0101[0].stay_rsv_cnt}}
									</div>
								</router-link>
							</li>
                            <!-- 레저/티켓 주석처리 함 -->
							<!-- <li>
								<router-link to="/mypage/noleisure">
									레저/티켓 구매내역
									<div class="mypage__menu-badge">
										{{mmy0101[0].leisure_rsv_cnt}}
									</div>
								</router-link>
							</li> -->
							<li>
								<router-link to="/mypage/cart" custom v-slot="{ navigate }">
									<a @click="isLogin ? navigate() : handleOpenLogin()">
										{{t('10289')}}
										<div class="mypage__menu-badge" v-if="mmy0101.length > 0">
											{{mmy0101[0].basket_cnt > 99 ? '99+' : mmy0101[0].basket_cnt}}
										</div>
									</a>	
								</router-link>
							</li>
							<li>
								<router-link to="/mypage/myreview" custom v-slot="{ navigate }">
									<a @click="isLogin ? navigate() : handleOpenLogin()">
										{{t('10372')}}
										<div class="mypage__menu-badge" v-if="mmy0101.length > 0">
											{{mmy0101[0].review_cnt > 99 ? '99+' : mmy0101[0].review_cnt}}
										</div>
									</a>	
								</router-link>
							</li>
							<li>
								<router-link to="/mypage/recent">
									{{t('10355')}} 
									<div class="mypage__menu-badge" v-if="mmy0101.length > 0">
										{{mmy0101[0].recent_cnt > 99 ? '99+' : mmy0101[0].recent_cnt}}
									</div>
								</router-link>
							</li>
							<li>
								<router-link to="/favorite/step1" custom v-slot="{ navigate }">
									<a @click="isLogin ? navigate() : handleOpenLogin()">
										{{t('10356')}}
										<small v-if="isLogin">
											{{styleText}}
										</small>
										<small v-else>
											<!-- 여행취향 입력하고 20% 쿠폰받기 -->
										</small>
									</a>
								</router-link>
							</li>
							<li>
								<router-link to="/mypage/inquiry/list"  custom v-slot="{ navigate }">
									<a @click="isLogin ? navigate() : handleOpenLogin()">
										{{t('10358')}} 
										<div class="mypage__menu-badge" v-if="mmy0101.length > 0">
											{{mmy0101[0].ques_cnt > 99 ? '99+' : mmy0101[0].ques_cnt}}
										</div>
									</a>
								</router-link>
							</li>
							<li>
								<router-link to="/mypage/settings">
									{{t('10359')}}
								</router-link>
							</li>
						</ul>
						<!-- [D] 헬로스테이 고객센터 -->
						<div class="box box--reverse">
							<div class="cs">
								<h3 class="cs__title">{{t('10360')}}</h3>
								<div class="cs__item">
									<a href="tel:02-407-8640" class="btn btn-cstel">
										<i class="icon icon-tel--fill"></i>
										{{t('10361')}}
									</a>
									<h4 class="cs__subtitle">{{t('10362')}}</h4>
									<p class="cs__desc">
                                        09:00 ~ 18:00
									</p>
								</div>
								<div class="cs__item">
									<a href="http://pf.kakao.com/_ZMssxj/chat" class="btn btn-cskakao">
										<i class="icon icon-kakao--fill"></i>
										{{t('10364')}}
									</a>
									<h4 class="cs__subtitle">{{t('10365')}}</h4>
									<p class="cs__desc">
                                        09:00 ~ 18:00
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="mypage__banner" v-if="planList.length > 0">
           			<router-link :to="'/event/' + planList[0].PROMO_NO" class="card__link noneimage">
						<div class="imagebg" :style="{'background-image': 'url(\'' + planList[0].IMG_URL + '\')'}" />
          			</router-link>
				</div> -->
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
	<MessageLogin v-if="!isLogin && openLogin" :handleClose="handleCloseLogin" :handleSubmit="handleSubmit"/>
	<Army v-if="openArmy" :handleClose="handleCloseArmy" :handleClick="handleClickArmy"/>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router';
import MessageLogin from '@/components/layers/Message_login';
import Army from '@/components/layers/Army';
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {		
		ErrorAlert,
		MessageLogin,
		Army
	},
	setup: function() {
		const store = useStore();
		const router = useRouter();
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);
		const mcs01 = computed(() => store.state.mcs01.mcs01["10"]);
		// const planList = computed(() => store.state.mrm01.planList);
		const openErrorAlert = ref(false)
		const lang = ref();
		const openLogin = ref(false);
		const openArmy = ref(false);

		onMounted(()=>{
			lang.value = store.state.app.lang;
			i18n.global.locale.value = store.state.app.lang;
			if(isLogin.value) {
				store.dispatch("mmy01/fetchMmy0101", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});

				store.dispatch("mcs01/fetchMcs01", {
					proc_cd : "10"
				});

				// store.dispatch("mrm01/fetchPlanList");
			}

		});

		const point = computed(() => 
			isLogin.value && mmy0101?.value[0]?.mem_point !== undefined
				? parseInt(mmy0101?.value[0]?.mem_point).toLocaleString("en-US") + "P"
				: "-"
		);
		const coupon = computed(() => 
			isLogin.value && mmy0101?.value[0]?.cp_cnt !== undefined
				? mmy0101?.value[0]?.cp_cnt + "장" 
				: "-"
		);
		const stamp = computed(() => 
			isLogin.value && mmy0101?.value[0]?.stamp_cnt !== undefined
				? mmy0101?.value[0]?.stamp_cnt % 5 + "/5" 
				: "-"
		);

		const gradeData = { 
			"01" : "Regular",
			"02" : "VIP", 
			"03" : "VVIP"
		};
		
		const grade = computed(() => isLogin.value ? gradeData[userData.value.mem_grade] : "");
		const typeData = { 
			"01" : "Regular",
			"02" : "Company", 
			"03" : "Partner"
		};
		const type = computed(() => isLogin.value ? typeData[userData.value.mem_type] :"");

		const styleText = computed(() => 
			isLogin.value && mcs01?.value?.length > 0
				? mmy0101.value[0]?.liking_entry_yn === "Y" ? userData.value.nick_nm + "님 취향의 여행을 추천중 :)" : "취향 설정하고 할인쿠폰 받기"
				: ""
		);
		const onClickRecommendCode = async () => {
			await navigator.clipboard.writeText(mmy0101.value[0].recomm_cd);
			//alert("추천코드가 복사되었습니다.");					
			store.commit("app/SET_RESMESSAGE", {res_message: null});
			store.commit("app/SET_RESMESSAGE", {res_message: "추천코드가 복사되었습니다."});
			openErrorAlert.value = true;
		};
		const { t }= useI18n() //번역필수 모듈

		
    	if(userData.value.mem_id === undefined){
    	    store.commit("SET_USERDATA", {userData: {}});
			store.commit('mhm01/RESET_ALL');
    	}
		return {
			handleOpenLogin: () => {
				openLogin.value = true;
			},
			handleCloseLogin: () => {
				openLogin.value = false;
			},
			handleSubmit: async () => {
				router.push("/layers/login");
			},
			handleOpenArmy: () => {
				openArmy.value = true;
			},
			handleCloseArmy: () => {
				openArmy.value = false;
			},
			userData,
			isLogin,
			point,
			coupon,
			stamp,
			lang,
			mmy0101,
			grade,
			type,
			onClickRecommendCode,
			styleText,
			// planList,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			openLogin,
			openArmy,
			t,
			i18n
		}
	}
}
</script>