<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10631')}}
					</h3>
					<button class="btn-close" @click="handleClose">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<div class="army">
						<p class="army__description" v-html="t('10633')">
						</p>
						<div class="army__buttons">
							<button type="button" class="btn-army" @click="handleClick('S')">
												<span class="btn-army__title">
													{{t('10634')}}
												</span>
								<span class="btn-army__subtitle">
													{{t('10635')}}
												</span>
								<i class="icon icon-right--light"></i>
							</button>
							<button type="button" class="btn-army"  @click="handleClick('O')">
												<span class="btn-army__title">
													{{t('10636')}}
												</span>
								<span class="btn-army__subtitle">
													{{t('10637')}}
												</span>
								<i class="icon icon-right--light"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
    props: ["handleClose", "handleClick"],
	setup(props) {
		const { t }= useI18n() //번역필수 모듈
		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
    	return {
    	  t,  //번역필수 모듈
		  i18n
		}
	} 
}
</script>